// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';

import NodeBody from '~plugins/prismic/components/NodeBody';
import useGroupedSlicesByType from '~plugins/prismic/hooks/useGroupedSlicesByType';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import type { PrismicNewsEntry } from '~schema';
import ComplexHeader from '~components/ComplexHeader';
import TextSlice from '~components/slices/TextSlice';
import QuoteSlice from '~components/slices/QuoteSlice';
import FooterContactForm from '~components/FooterContactForm';
import newDate from '~helpers/newDate';
import useAllNodeBodyComponents from '~hooks/useAllNodeBodyComponents';

import { SLICE_TABBED_CARD_GROUPED_NAME } from '../../constants';

export type ClassKey = 'root' | 'header' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicNewsEntry>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    textAlign: 'center',
  },
  bodySection: {},
});
const { download_form, ...allNodeBodyComponents } = useAllNodeBodyComponents();

const nodeBodyComponents = {
  text: TextSlice,
  quote: QuoteSlice,
  ...allNodeBodyComponents,
};

const NewsEntryPage = ({ node, classes }: Props) => {
  if (!node) {
    return null;
  }

  const publication_date = newDate(
    node?.data?.publication_date || node.first_publication_date,
  );
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const intl = useIntl();

  const body = useGroupedSlicesByType({
    slices: node?.data?.body,
    sliceType: 'tabbed_cards',
    groupedType: SLICE_TABBED_CARD_GROUPED_NAME,
  });

  return (
    <div className={classes.root}>
      <ComplexHeader
        title={node?.data?.title}
        subtitle={node?.data?.subtitle}
        authorLink={node?.data?.author}
        publicationDate={intl.formatDate(publication_date, options)}
        tags={node?.data?.tags}
        backgroundImage={node?.data?.background_image}
        containerMaxWidth="md"
        classes={{ root: classes.header }}
      />
      <NodeBody
        node={node}
        body={body}
        components={nodeBodyComponents}
        className={classes.bodySection}
      />
      <FooterContactForm currentPage={node?.data?.name?.text} />
    </div>
  );
};

export default compose(
  withNodePage<PrismicNewsEntry, *>({
    getNode: data => data?.prismicNewsEntry,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'NewsEntryPage' }),
)(NewsEntryPage);

export const query = graphql`
  query PrismicNewsEntry($prismicId: ID) {
    prismicNewsEntry(prismicId: { eq: $prismicId }) {
      id
      lang
      type
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        publication_date
        tags {
          tag {
            document {
              ... on PrismicEntryTag {
                id
                uid
                data {
                  name {
                    text
                    html
                  }
                }
              }
            }
          }
        }
        author {
          link_type
          url
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                email {
                  text
                }
              }
            }
          }
        }
        background_image {
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicNewsEntryBodyText {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_text {
                html
              }
            }
          }
          ... on PrismicNewsEntryBodyQuote {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_quote {
                text
                html
              }
              slice_quote_name {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 250) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyImageGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              image_width
              max_line_items
            }
            items {
              item_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_caption {
                text
                html
              }
            }
          }
          ... on PrismicNewsEntryBodyVideo {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_link {
                link_type
                url
              }
              video_width
              autoplay
              slice_image_placeholder {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyTextWithImage {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              overlapping_image
              image_position
            }
            items {
              item_highlighted_text {
                text
                html
              }
            }
          }
          ... on PrismicNewsEntryBodyItemsList {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              overlapping_image
              image_position
            }
            items {
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyHighlightedItems {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 70, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewsEntryBodySingleImage {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_caption {
                text
                html
              }
              image_width
            }
          }
          ... on PrismicNewsEntryBodyImageGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              image_width
              max_line_items
            }
            items {
              item_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_caption {
                text
                html
              }
            }
          }
          ... on PrismicNewsEntryBodyVideo {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_link {
                link_type
                url
              }
              video_width
              autoplay
              caption {
                text
                html
              }
              slice_image_placeholder {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyHighlightedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              image_position
            }
            items {
              item_title {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyRelatedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyBanner {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_overlapping_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image_color
              slice_background_color
            }
            items {
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_style
            }
          }
          ... on PrismicNewsEntryBodySolutions {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_background_image {
                alt
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyLinksGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicNewsEntryBodyTitleAndDescription {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_title_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              container_width
            }
          }
          ... on PrismicNewsEntryBodyTabbedCards {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_tab_name {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
            }
            items {
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyArViewer {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_glb_model_link {
                link_type
                url
              }
              select_usdz_model_link {
                link_type
                url
              }
              slice_background_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              viewer_button
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyPrice {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_price {
                text
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image_color
              slice_background_color
              overlapping_image
            }
            items {
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_style
            }
          }
          ... on PrismicNewsEntryBodyPrices {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_price {
                text
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyEmbeddedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_embedded_content {
                text
                html
              }
            }
          }
          ... on PrismicNewsEntryBodyButtonCards {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
              }
              item_description {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyContentGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicNewsEntryBodyCallToAction {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_variant
            }
          }
          ... on PrismicNewsEntryBodyFloatingButton {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicNewsEntryBodyIconsGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              max_line_items
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicNewsEntryBodyIconsCarousel {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;
